import { motion } from "framer-motion";
import React from "react";
import { slowEase } from "../styles/animations/framer_transitions";

function LandingSection({ title, subtitle }: IProps) {
  return (
    <section id="landing" className="w-full dark:text-white">
      <div className="mt-32 sm:mt-60 mb-32">
        <motion.h2
          className="text-xl sm:text-4xl font-body antialiased select-none"
          initial={{ y: -50, opacity: 0 }}
          animate={{
            y: 0,
            opacity: 1,
            transition: {
              ease: [0.6, 0.1, 0.2, 1],
              duration: 1,
              delay: 0.1,
            },
          }}
          exit={{
            y: -50,
            opacity: 0,
            transition: {
              ...slowEase,
              duration: 1,
              delay: 0.1,
            },
          }}
        >
          {subtitle}
        </motion.h2>
        <motion.h1
          className="bg-clip-text text-transparent gradient-bg dark:gradient-bg text-6xl sm:text-9xl font-display font-bold antialiased uppercase select-none bg-no-repeat"
          initial={{ y: -50, opacity: 0 }}
          animate={{
            y: 0,
            opacity: 1,
            transition: {
              ...slowEase,
              duration: 1,
              delay: 0.2,
            },
          }}
          exit={{
            y: -50,
            opacity: 0,
            transition: {
              ease: [0.6, 0.1, 0.2, 1],
              duration: 1,
              delay: 0.2,
            },
          }}
        >
          {title}
        </motion.h1>
      </div>
    </section>
  );
}

interface IProps {
  title: string;
  subtitle: string;
}

export default LandingSection;
