import * as React from "react";
import SEO from "../components/layout_elements/SEO";
import LandingSection from "../components/LandingSection";
import Button from "../components/Button";
import { motion } from "framer-motion";
import ScrollCallToAction from "../components/ScrollCallToAction";
import { FaLaptopCode } from "react-icons/fa";
import { Link } from "gatsby";
import TextContainer from "../components/TextContainer";

const IndexPage = () => {
  return (
    <>
      <>
        <SEO />
        <LandingSection
          title="Zach Mumbauer"
          subtitle="Full-stack Software Engineer"
        />
        <ScrollCallToAction />
        <TextContainer>
          <p>
            <WaivingHand />
            &nbsp;Hello! Thanks for coming to my website!
            <br /> <br />
            My name is Zach Mumbauer.
            <br />I am a full-stack software engineer based in Providence, RI.
          </p>
        </TextContainer>
        <TextContainer>
          <FaLaptopCode className="m-auto text-5xl mb-5" />
          <p className="mb-10">
            Presently, I am working as a software engineer on the disputes team
            at{" "}
            <Link to="/pay-pal-braintree" className="link">
              PayPal Braintree
            </Link>
          </p>
          <div>
            <Button link="/resume/">Check out my resume</Button>
          </div>
        </TextContainer>
      </>
    </>
  );
};

const WaivingHand = () => {
  return (
    <motion.span
      initial={{ rotate: 0 }}
      whileInView={{
        rotate: [0, 14, -8, 14, -4, 10, 0, 0],
        transition: {
          repeat: Infinity,
          duration: 2,
          times: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 1],
        },
      }}
      className="inline-block"
      style={{ transformOrigin: "70% 70%" }}
    >
      👋
    </motion.span>
  );
};

export default IndexPage;
