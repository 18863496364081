import React from "react";
import { motion } from "framer-motion";

const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

const lineVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

const ScrollCallToAction = () => {
  return (
    <div className="mb-32">
      <motion.p
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="text-stone dark:text-white font-body uppercase text-center mb-5 antialiased slow-transition select-none"
      >
        Scroll down
      </motion.p>
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <motion.span
          variants={lineVariants}
          transition={{
            duration: 0.5,
            repeat: Infinity,
            repeatDelay: 0.75,
            repeatType: "mirror",
          }}
          key="line1"
          className="h-0.5 w-36 bg-stone dark:bg-white rounded-full block mx-auto mb-5 slow-transition"
        ></motion.span>
        <motion.span
          variants={lineVariants}
          transition={{
            delay: 0.5,
            duration: 0.5,
            repeat: Infinity,
            repeatDelay: 0.75,
            repeatType: "mirror",
          }}
          key="line2"
          className="h-0.5 w-28 bg-stone dark:bg-white rounded-full block mx-auto mb-5 slow-transition"
        ></motion.span>
        <motion.span
          variants={lineVariants}
          transition={{
            delay: 1,
            duration: 0.5,
            repeat: Infinity,
            repeatDelay: 0.75,
            repeatType: "mirror",
          }}
          key="line3"
          className="h-0.5 w-20 bg-stone dark:bg-white rounded-full block mx-auto mb-5 slow-transition"
        ></motion.span>
        <motion.span
          variants={lineVariants}
          transition={{
            delay: 1.5,
            duration: 0.5,
            repeat: Infinity,
            repeatDelay: 0.75,
            repeatType: "mirror",
          }}
          key="line4"
          className="h-0.5 w-16 bg-stone dark:bg-white rounded-full block mx-auto mb-5 slow-transition"
        ></motion.span>
      </motion.div>
    </div>
  );
};

export default ScrollCallToAction;
